import {
  Alert,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Select, SvgIcon, TextField,
  Tooltip,
  Typography
} from '@mui/material';
import colorsStyle from '../../colors.module.scss';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useData } from '../../DataContext';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import styles from '../../styles/offer.module.scss';
import InputFileUpload, { VisuallyHiddenInput } from '../../components/upload-button';
import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { TextMaskCustom } from '../../components/text-filed-formatter';
import { deleteDcoument, uploadDocument } from '../../api';
import { getErrorMessage } from '../../utils';

export function getColorByStatus(status) {
  switch (status) {
    case 'Denied':
      return 'red';
    case 'Approved':
      return 'green';
    case 'Pending':
      return 'orange';
    default:
      return 'black';
  }
};

export function DocumentUploadTable({ setIsLoading }) {
  // all file types that already uploaded
  const [selected, setSelected] = useState(new Set());
  const { offerCalculatorFactor, offerConstants, files, setFiles } = useData();
  const documentTypes = offerConstants.documentTypes;
  const [hasFiles, setHasFiles] = useState(false);
  let [submittedResult, setSubmittedResult] = useState({
    show: false,
    succeed: false,
    msg: ''
  });
  const [key, setKey] = useState(0);
  const [emailOrPhoneUpdated, setEmailOrPhoneUpdated] = useState({
    email: false,
    phone: false
  });

  useEffect(() => {
    const newSelected = new Set();
    let newHasFile = false;
    files.forEach(file => {
      if (!file.isAdditional) {
        newHasFile = true;
      }
      newSelected.add(file.id);
    });
    setSelected(newSelected);
    setHasFiles(newHasFile);
  }, [files]);

  // The prompt message is triggered to display for 5 seconds before disappearing.
  useEffect(() => {
    let timer;
    if (submittedResult.show && submittedResult.succeed) {
      console.log(`will hide the message in 5 seconds`);
      timer = setTimeout(() => {
        setSubmittedResult(prevState => ({
          ...prevState,
          show: false
        }));
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [key, submittedResult.succeed]);

  const updateFileInfo = (file, respData) => {
    file.status = respData.status;
    file.data = { name: respData.docName };
    file.uuid = respData.uuid;
    file.id = respData.docType;
    file.status = respData.status;
    file.email = respData.email;
    file.phone = respData.phone;
    file.data = {
      name: respData.docName
    };
    file.isAdditional = respData.isAdditional;
  };

  const onFileUpload = (uploadedFiles) => {
    const updatedFiles = [...files];
    for (const uploadedFile of uploadedFiles) {
      updatedFiles.push({
        id: '',
        data: uploadedFile,
        isAdditional: false
      });
    }
    setFiles(updatedFiles);
  };

  const onReplaceFile = async (uuid, event) => {
    const updatedFiles = [...files];
    const uploadedFile = event.target.files[0];
    for (const updatedFile of updatedFiles) {
      if (updatedFile.uuid === uuid) {
        try {
          setIsLoading(true);
          const resp = await uploadDocument(offerCalculatorFactor.uuid, updatedFile.uuid, updatedFile.id, uploadedFile, updatedFile.email, updatedFile.phone, false);
          updateFileInfo(updatedFile, resp.data);
          setFiles(updatedFiles);
          event.target.value = '';
          updateMessage(true, `Upload/Update File ${updatedFile.data.name} Successfully`);
        } catch (e) {
          updateMessage(false, getErrorMessage(`Upload/Update File ${updatedFile.data.name} Error`, e));
        } finally {
          setIsLoading(false);
        }
        break;
      }
    }

  };


  const onSelectedFileType = async (index, value) => {
    const updatedFiles = [...files];
    const updatedSelected = new Set(selected);
    const theFile = updatedFiles[index];
    const oldValue = theFile.id;
    theFile.id = value;
    updatedSelected.delete(oldValue);
    updatedSelected.add(value);

    try {
      setIsLoading(true);
      const resp = await uploadDocument(offerCalculatorFactor.uuid, theFile.uuid, theFile.id, theFile.data, theFile.email, theFile.phone, theFile.isAdditional);
      updateFileInfo(theFile, resp.data);
      setFiles(updatedFiles);
      setSelected(updatedSelected);
      updateMessage(true, `Upload/Update File ${theFile.data.name} Successfully`);
    } catch (e) {
      console.log(`upload file error: ${e}`);
      updateMessage(false, getErrorMessage(`Upload/Update File ${theFile.data.name} Error`, e));
    } finally {
      setIsLoading(false);
    }

  };

  const onDeleteFile = async (index) => {
    const updatedFiles = [...files];
    const file = updatedFiles[index];
    const fileId = file.id;
    if (fileId) {
      const updatedSelected = new Set(selected);
      updatedSelected.delete(fileId);
      setSelected(updatedSelected);
    }
    if (file.uuid) {
      try {
        setIsLoading(true);
        await deleteDcoument(offerCalculatorFactor.uuid, file.uuid);
        updateMessage(true, `Delete File ${file.data.name} Successfully`);
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
      } catch (e) {
        updateMessage(false, getErrorMessage(`Delete File ${file.data.name} Error`, e));
      } finally {
        setIsLoading(false);
      }
    }

  };

  const onEmailPhoneChange = async (index, emailOrPhone, event) => {
    const updatedFiles = [...files];
    updatedFiles[index][emailOrPhone] = event.target.value;
    setFiles(updatedFiles);
    setEmailOrPhoneUpdated(prevState => ({
      ...prevState,
      [emailOrPhone]: true
    }));
  };

  const onEmailPhoneBlur = async (index, emailOrPhone) => {
    const updatedFiles = [...files];
    const theFile = updatedFiles[index];
    if (!emailOrPhoneUpdated[emailOrPhone]) {
      return;
    }
    setEmailOrPhoneUpdated(prevState => ({
      ...prevState,
      [emailOrPhone]: false
    }));
    try {
      setIsLoading(true);
      const resp = await uploadDocument(offerCalculatorFactor.uuid, theFile.uuid, theFile.id, theFile.data, theFile.email, theFile.phone, theFile.isAdditional);
      updateFileInfo(theFile, resp.data);
      updateMessage(true, `Upload/Update File ${theFile.data.name} Successfully`);
    } catch (e) {
      const errorMessage = getErrorMessage(`Upload/Update File ${theFile.data.name} Error`, e);
      updateMessage(false, errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const updateMessage = (succeed, msg) => {
    setSubmittedResult({
      show: true,
      succeed: succeed,
      msg: msg
    });
    setKey(prev => prev + 1);
  };


  return (
    <>
      {hasFiles &&
        (<TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableCell>File Type</TableCell>
                <TableCell>Replace</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell>Review Status</TableCell>
                <TableCell>Email(Optional)</TableCell>
                <TableCell>Phone(Optional)</TableCell>
                <TableCell>Actions</TableCell>
              </TableHead>
              <TableBody>
                {files.map((file, index) => (
                  file.isAdditional ? '' :
                    <TableRow key={file.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        <FormControl error={!file.id} className={styles.fileType}>
                          <Select
                            displayEmpty
                            disabled={file.status === 'Approved'}
                            size={'small'}
                            fullWidth={true}
                            value={file.id}
                            renderValue={(selected) => {
                              if (!selected) {
                                return <em>Select Type...</em>;
                              }
                              return selected;
                            }}
                            SelectDisplayProps={{
                              style: {
                                padding: '10px 32px 10px 14px'
                              }
                            }}
                            onChange={(event) => {
                              onSelectedFileType(index, event.target.value);
                            }}
                          >
                            {documentTypes.map((item, index) => (
                              <MenuItem key={index} value={item.id}
                                        disabled={selected.has(item.id)}>{`${item.name} (${item.id})`}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={`replace ${file.id}`}>
                          <IconButton
                            component="label"
                            variant="contained"
                            tabIndex={-1}
                            disabled={file.status === 'Approved'}
                            sx={{ color: colorsStyle.dropboxBlue }}
                          >
                            <VisuallyHiddenInput type="file" onChange={(e) => onReplaceFile(file.uuid, e)}
                                                 accept=".pdf,.png,.jpg,.jpeg"></VisuallyHiddenInput>
                            <SvgIcon>
                              <UploadIcon />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={file.data?.name}>
                    <span
                      style={{
                        display: 'inline-block',
                        maxWidth: '150px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        verticalAlign: 'middle'
                      }}
                    >
                      {file.data?.name}
                    </span>
                        </Tooltip>
                      </TableCell>

                      <TableCell>
                        <span style={{ color: getColorByStatus(file.status), fontWeight: 'bold' }}>{file.status}</span>
                      </TableCell>
                      <TableCell>
                        <Input disabled={file.status === 'Approved'} value={file.email}
                               onChange={e => onEmailPhoneChange(index, 'email', e)}
                               onBlur={e => onEmailPhoneBlur(index, 'email')}
                        >
                          >
                        </Input>
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="phone"
                          variant="standard"
                          disabled={file.status === 'Approved'}
                          onChange={(e) => onEmailPhoneChange(index, 'phone', e)}
                          onBlur={e => onEmailPhoneBlur(index, 'phone')}
                          value={String(file.phone)}
                          InputProps={{
                            inputComponent: TextMaskCustom
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title={`delete ${file.id}`}>
                          <IconButton aria-label="delete" sx={{ color: 'red' }} onClick={() => onDeleteFile(index)}
                                      disabled={file.status === 'Approved'}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>

          </TableContainer>
        )}
      {submittedResult.show && (
        <Alert variant="filled" severity={submittedResult.succeed ? 'success' : 'error'}>
          {submittedResult.msg}
        </Alert>
      )}
      <InputFileUpload disabled={files.filter(f => !f.isAdditional).length === documentTypes.length}
                       onFileUpload={onFileUpload}
                       className={styles.uploadModalButton} />
    </>
  );
}
