import {
  Alert, Backdrop,
  Box, Button, CircularProgress,
  Modal,
  Stack, Typography
} from '@mui/material';
import colorsStyle from '../../colors.module.scss';
import { useData } from '../../DataContext';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { deleteDcoument, submitOffer, uploadDocument } from '../../api';
import { DocumentUploadTable } from './document-upload-table';
import { AdditionalDocumentsTable } from './additional-documents-table';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: colorsStyle.coconut,
  boxShadow: 24,
  borderRadius: colorsStyle.borderRadius,
  p: 4,
  textAlign: 'center',
  maxHeight: '80vh', // Set maximum height
  overflowY: 'auto', // Make content scrollable
};


export function UploadModal({ disabled, open, handleClose, isLoading, setIsLoading, setSubmittedResult, updateOffer }) {
  // all file types that already uploaded
  const { offerCalculatorFactor, offerConstants, files } = useData();

  const onSubmit = () => {
    const _submit = async () => {
      setIsLoading(true);
      // then upload files
      submitOffer(offerCalculatorFactor.uuid, offerCalculatorFactor).then((resp) => {
        setSubmittedResult({
          submitted: true,
          succeed: true,
          msg: 'submitted successfully'
        });
        updateOffer();
        handleClose();
      })
        .catch((e) => {
          console.log(e)
          let errorMessage = `submitted error`;
          if (e.response && e.response.data.error) {
            errorMessage += `: ${e.response.data.error}`;
          }
          setSubmittedResult({
            submitted: true,
            succeed: false,
            msg: errorMessage
          });
        }).finally(() => {
        setIsLoading(false);
      });
    };
    _submit();

  };

  return (

    <Modal

      open={open}
      onClose={handleClose}>

      <Box sx={style}>
        <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack direction={'column'} spacing={3}>
          <Stack direction={'column'} spacing={1}>
            <Typography>Documents upload to request contracts</Typography>
            <DocumentUploadTable setIsLoading={setIsLoading}></DocumentUploadTable>
          </Stack>

          <Stack direction={'column'} spacing={1}>
            <Typography>Documents upload to requisite approval conditions</Typography>
            <AdditionalDocumentsTable setIsLoading={setIsLoading}/>
          </Stack>

          <Stack direction={'row'} justifyContent={'flex-end'} spacing={3}>
            <Button variant="contained"
                    color={'info'}
                    sx={{ fontWeight: 'bold' }} onClick={handleClose}
            >Cancel</Button>
            <Button disabled={disabled} variant="contained"
                    color={'secondary'} size={'large'}
                    sx={{ fontWeight: 'bold' }} onClick={onSubmit}
            >{disabled ? 'Submitted' : 'Submit'}</Button>
          </Stack>

        </Stack>
      </Box>

    </Modal>
  );
}
