import { Alert, IconButton, SvgIcon, Tooltip } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import * as React from 'react';
import { useData } from '../../DataContext';
import TableRow from '@mui/material/TableRow';
import colorsStyle from '../../colors.module.scss';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useRef, useState } from 'react';
import { VisuallyHiddenInput } from '../../components/upload-button';
import { getColorByStatus } from './document-upload-table';
import { StyledTableCell, StyledTableRow } from './repayment-table';
import { deleteDcoument, uploadDocument } from '../../api';
import { getErrorMessage } from '../../utils';

export function AdditionalDocumentsTable({ style, readonly, setIsLoading }) {
  const { offerCalculatorFactor, files, setFiles } = useData();
  const [filesMap, setFilesMap] = useState(new Map());
  let [submittedResult, setSubmittedResult] = useState({
    show: false,
    succeed: false,
    msg: ''
  });
  const [key, setKey] = useState(0);


  useEffect(() => {
    const newFilesMap = new Map();
    files.forEach(file => {
      if (file.isAdditional)
        newFilesMap.set(file.id, file);
    });
    setFilesMap(newFilesMap);
  }, [files]);

  // The prompt message is triggered to display for 5 seconds before disappearing.
  useEffect(() => {
    let timer;
    if (submittedResult.show && submittedResult.succeed) {
      console.log(`will hide the message in 5 seconds`);
      timer = setTimeout(() => {
        setSubmittedResult(prevState => ({
          ...prevState,
          show: false
        }));
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [key, submittedResult.succeed]);

  const onDeleteFile = async (field) => {
    const updatedFiles = [...files];
    if (filesMap.has(field)) {
      const newFilesMap = new Map(filesMap);
      newFilesMap.delete(field);
      setFilesMap(newFilesMap);
    }
    let index;
    for (let i = 0; i < updatedFiles.length; i++) {
      if (updatedFiles[i].id === field) {
        index = i;
        break;
      }
    }
    if (!index) {
      return;
    }
    let file = updatedFiles[index];
    if (file.uuid) {
      try {
        setIsLoading(true);
        await deleteDcoument(offerCalculatorFactor.uuid, file.uuid);
        updateMessage(true, `File ${file.data.name} deleted successfully`);
      } catch (e) {
        updateMessage(false, getErrorMessage(`Failed to delete file ${file.data.name}`, e));
      } finally {
        setIsLoading(false);
      }
    }
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const onReplaceFile = async (field, event) => {
    console.log('uploaded', event.target.files);
    console.log(field);
    const updatedFiles = [...files];
    const uploadedFile = event.target.files[0];
    const oldFile = filesMap.get(field);
    const newFilesMap = new Map(filesMap);
    if (oldFile) {
      oldFile.data = uploadedFile;
      newFilesMap.set(field, oldFile);
    } else {
      const newFile = {
        id: field,
        data: uploadedFile,
        isAdditional: true
      };
      updatedFiles.push(newFile);
      newFilesMap.set(field, newFile);
    }
    try {
      setIsLoading(true);
      const theFile = newFilesMap.get(field);
      console.log(`trying to upload file: ${uploadedFile.name}`);
      const resp = await uploadDocument(offerCalculatorFactor.uuid, theFile.uuid, theFile.id, theFile.data, null, null, true);
      theFile.uuid = resp.data.uuid;
      theFile.status = resp.data.status;
      theFile.data = { name: resp.data.docName };
      setFiles(updatedFiles);
      setFilesMap(newFilesMap);
      updateMessage(true, `File ${theFile.data.name} uploaded successfully`);
    } catch (e) {
      updateMessage(false, getErrorMessage(`Failed to upload/replace file ${uploadedFile.name}`, e));
    } finally {
      setIsLoading(false);
    }
    event.target.value = '';
  };

  const updateMessage = (succeed, msg) => {
    setSubmittedResult({
      show: true,
      succeed: succeed,
      msg: msg
    });
    setKey(prev => prev + 1);
  }

  const isDisabled = (status) => {
    return status === 'Approved';
  }


  return (
    <>
      <TableContainer component={Paper} style={style}>
        <Table>

          <TableHead>
            {readonly ? (
              <StyledTableRow>
                <StyledTableCell>File Type</StyledTableCell>
                <StyledTableCell>File Name</StyledTableCell>
                <StyledTableCell>Review Status</StyledTableCell>
              </StyledTableRow>
            ) : (<>
                <TableCell>File Type</TableCell>
                <TableCell>Upload/Replace</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell>Review Status</TableCell>
                <TableCell>Actions</TableCell></>
            )}

          </TableHead>
          <TableBody>

            {offerCalculatorFactor.requirements.filter(item => item.enabled).map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.field}</TableCell>
                {readonly ? '' : (
                  <TableCell style={{ paddingLeft: '40px' }}>
                    <Tooltip title={`replace ${item.field}`}>
                      <IconButton
                        component="label"
                        variant="contained"
                        tabIndex={-1}
                        disabled={isDisabled(filesMap.get(item.field)?.status)}
                        sx={{ color: colorsStyle.dropboxBlue }}
                      >
                        <VisuallyHiddenInput id={`addi-file-replace-${index}`} type="file"
                                             onChange={(e) => onReplaceFile(item.field, e)}></VisuallyHiddenInput>
                        <SvgIcon>
                          <UploadIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}

                <TableCell>
                  <Tooltip title={filesMap.get(item.field)?.data?.name}>
                    <span
                      style={{
                        display: 'inline-block',
                        maxWidth: '150px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        verticalAlign: 'middle'
                      }}
                    >
                      {filesMap.get(item.field)?.data?.name}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <span style={{
                    color: getColorByStatus(filesMap.get(item.field)?.status),
                    fontWeight: 'bold'
                  }}>{filesMap.get(item.field)?.status}</span>
                </TableCell>
                {readonly ? '' : (<TableCell>
                  {
                    <Tooltip title={`delete ${item.field}`}>
                      <IconButton aria-label="delete" sx={{ color: 'red' }}
                                  onClick={() => onDeleteFile(item.field)}
                                  disabled={isDisabled(filesMap.get(item.field)?.status) || !filesMap.has(item.field)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </TableCell>)}

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {submittedResult.show && (
        <Alert variant="filled" severity={submittedResult.succeed ? 'success' : 'error'}>
          {submittedResult.msg}
        </Alert>
      )}
    </>);
}
