import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload({disabled, onFileUpload, className}) {
  const handleFileUpload = (event) => {
    console.log('uploaded', event.target.files)
    onFileUpload(event.target.files);
    event.target.value = '';
  };

  return (
    <Button
      component="label"
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      className={className}
      disabled={disabled}
    >
      Choose Files
      <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept=".pdf,.png,.jpg,.jpeg" multiple/>
    </Button>
  );
}
