import { PaymentFrequency } from './constants';

export const mockOfferCalculatorFactor = {
    merchant: 'Sip Sip Liquor Shop, INC.',
    referralPartner: 'Jackie Chan',
    durationMatrix: {
        [PaymentFrequency.Daily]: 20,
        [PaymentFrequency.Weekly]: 4
    },
    buyRate: 1.27,
    factor: 1.37,
    loadAmountMin: 50000,
    loadAmountMax: 100000,
    loadAmount: 75000,
    termMin: 5,
    termMax: 12,
    term: 12,
    commissionMin: 0,
    commissionMax: 10,
    commission: 10,
    origination: 2.5,
    prePaymentBuyRates: [],
    frequency: PaymentFrequency.Weekly
};
