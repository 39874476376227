// DataContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { mockOfferCalculatorFactor } from './mocks';
import { getConstants } from './api';

// Initialize the context with a default value
const DataContext = createContext(undefined);

// Create a provider component
export const DataProvider = ({ children }) => {
    const [offerCalculatorFactor, setOfferCalculatorFactor] = useState({ mockOfferCalculatorFactor });
  const [files, setFiles] = useState([]);
  const [offerConstants, setOfferConstants] = useState({});

    useEffect(() => {
      getConstants().then((resp) => {
        setOfferConstants(resp.data)
      }).catch((err) => {

      })
    }, [])

    return (
        <DataContext.Provider
            value={{ files, setFiles, offerCalculatorFactor, setOfferCalculatorFactor, offerConstants }}>
            {children}
        </DataContext.Provider>
    );
};

// Hook to use the context
export const useData = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};
