import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useData } from '../../DataContext';
import { formatFloat, formatUSD } from '../../utils';
import { styled, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2d70ba',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.common.white
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));


export default function RepaymentTable({ style }) {

  const { offerCalculatorFactor } = useData();

  const getFactor = (a, b) => {
    return (parseFloat(a) * 0.01 || 0) + (parseFloat(b) || 0);
  };

  return (
    <TableContainer component={Paper} style={style}>
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Pre-Payment Schedule</StyledTableCell>
            <StyledTableCell>Buy Rate</StyledTableCell>
            <StyledTableCell>Factor Rate</StyledTableCell>
            <StyledTableCell>Payback</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {offerCalculatorFactor.prePaymentBuyRates.map((rate, index) => {
            let factor = 0;
            let payBack = 0;
            if (rate) {
              factor = getFactor(offerCalculatorFactor.commission, rate);
              payBack = offerCalculatorFactor.loadAmount * factor;
            }
            return (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  MONTH {index + 1}
                </StyledTableCell>
                <StyledTableCell>{rate}</StyledTableCell>
                <StyledTableCell>{factor !== 0 ? formatFloat(factor) : ''}</StyledTableCell>
                <StyledTableCell>{payBack !== 0 ? formatUSD(payBack) : ''}</StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
