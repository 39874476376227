import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './styles.scss';
import { DataProvider } from './DataContext';
import Home from './pages/home';
import Calculator from './pages/calculator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import colors from './colors.module.scss';

const theme = createTheme({
  typography: {
    fontFamily: colors.primaryFont
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
      }
    }
  },
  palette: {
    primary: { main: colors.dropboxBlue, light: colors.dropboxBlueLight },
    secondary: { main: '#ef8d53', contrastText: '#ffffff' },
    info: { main: colors.coconut },
    // error: {
    //   main: colors.graphite
    // },
    action: {
      disabledBackground: 'grey'
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <DataProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/:uuid" element={<Calculator />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </DataProvider>
  </StrictMode>
);
