export const CalculateType = {
    LoadAmount : 'Funding Amount',
    Term : 'Term (Months)',
    Commission : 'Commission',
    Origination : 'Origination Fee'
}

export const PaymentFrequency = {
    Daily : 'Daily',
    Weekly : 'Weekly',
    BiWeekly : 'Bi-Weekly',
    Monthly : 'Monthly'
}
