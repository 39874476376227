import { Stack } from '@mui/material';


export function Home() {
  return (
    <Stack alignItems="center">
      <h2>Welcome</h2>
    </Stack>
  );
}


export default Home;
