import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Stack, styled, tableCellClasses, Typography } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2d70ba",
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.common.white
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const rows = [
  ['Signed Agreement', 'Signed Agreement', 'Signed Agreement'],
  ['DL+VC', 'DL+VC', 'DL+VC'],
  ['Bank Verification', 'Bank Verification', 'Bank Verification'],
  ['Tax Return (Check with UW this may be waived at times)', 'Tax Return (Check with UW this may be waived at times)', 'Tax Return (Check with UW this may be waived at times)'],
  ['', 'Financials', 'Financials'],
  ['', '12 Months Banks', '12 Months Banks'],
  ['', '', '8821']
];

export default function ApprovalList({ style }) {

  return (
    <Stack spacing={1} alignItems="center">
    <TableContainer component={Paper} style={style}>
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>FUNDING BETWEEN LESS THAN $250,0000</StyledTableCell>
            <StyledTableCell>FUNDING BETWEEN $250,000-$350,0000</StyledTableCell>
            <StyledTableCell>FUNDING $350,0000+</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell>{row[0]}</StyledTableCell>
              <StyledTableCell>{row[1]}</StyledTableCell>
              <StyledTableCell>{row[2]}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Stack>
  );
}
