import axios from 'axios';

// @ts-ignore
export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3003',
  timeout: process.env.REACT_APP_API_TIMEOUT_MS ? parseInt(process.env.REACT_APP_API_TIMEOUT_MS) : 10000,
  withCredentials: true
});


export const getConstants = async () => {
  return await axiosClient.get('/constants');
};

export async function getOffer(offerId) {
  return await axiosClient.get(`/offers/${offerId}`);
}


export async function submitOffer(offerId, args) {
  return await axiosClient.put(`/offers/${offerId}`, {
    loadAmount: args.loadAmount,
    term: args.term,
    commission: args.commission
  });
}

export async function deleteDcoument(offerId, docUuid) {
  return await axiosClient.delete(`/offers/${offerId}/documents/${docUuid}`);
}

export async function uploadDocument(offerId, docUuid, docId, file, email, phone, isAdditional) {
  const formData = new FormData();
  if (file instanceof File)
    formData.append('file', file);
  if (docUuid)
    formData.append('docUuid', docUuid || '');
  formData.append('docId', docId);
  formData.append('isAdditional', isAdditional);
  if (email)
    formData.append('email', email);
  if (phone)
    formData.append('phone', phone);
  return await axiosClient.post(`/offers/${offerId}/documents`, formData, {
    timeout: 0
  });
}


export async function getDocuments(offerId) {
  return await axiosClient.get(`/offers/${offerId}/documents`);
}
