import { Box, Slider, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import styles from '../../styles/calculatorSlider.module.scss';
import colors from '../../colors.module.scss';
import { useData } from '../../DataContext';
import { CalculateType } from '../../constants';

export function CalculatorSlider(props) {
  const { title, min, max, step, minLabel, maxLabel, defaultValue, inputFormat, disabled, helperText } = props;
  const { offerCalculatorFactor, setOfferCalculatorFactor } = useData();
  const [value, setValue] = React.useState(defaultValue);

  let inputFormatter;
  switch (inputFormat) {
    case 'dollar':
      inputFormatter = DollarFormat;
      break;
    case 'percentage':
      inputFormatter = PercentageFormat;
      break;
    default:
      inputFormatter = NumericFormatCustom;
  }
  ;

  const afterValueUpdated = (value) => {
    if (title === CalculateType.Term) {
      setOfferCalculatorFactor({ ...offerCalculatorFactor, term: value });
    } else if (title === CalculateType.Commission) {
      setOfferCalculatorFactor({ ...offerCalculatorFactor, commission: value });

    } else if (title === CalculateType.LoadAmount) {
      setOfferCalculatorFactor({ ...offerCalculatorFactor, loadAmount: value });
    }
  };

  useEffect(() => {
    afterValueUpdated(defaultValue);
  }, []);

  const valueUpdated = (newValue) => {
    setValue(newValue);
    afterValueUpdated(newValue);
  };


  const handleSlideChange = (event, newValue) => {
    valueUpdated(newValue);
  };


  const validateInput = (event) => {
    let newValue;
    if (event.target.value) {
      newValue = parseFloat(event.target.value);

      if (inputFormat === 'dollar') {
        newValue = parseFloat(event.target.value.replace(/[^\d.]/g, ''));
      }
    } else {
      newValue = -1;
    }

    if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    } else {
      return;
    }

    valueUpdated(newValue);
  };

  const handleTextChange = (event) => {
    valueUpdated(parseFloat(event.target.value));
  };


  return (
    <Stack direction={'row'} justifyContent={'space-between'} className={styles.container}>
      <div className={styles.left}>
        <Stack spacing={0.5}>
          <div className={styles.title}>{title}</div>
          <div>
            <TextField
              disabled={disabled}
              value={value}
              onChange={handleTextChange}
              onBlur={validateInput}
              variant="outlined"
              helperText={helperText}
              size={'small'}
              InputProps={{
                inputComponent: inputFormatter,
                style: {
                  width: '130px'
                }
              }}
              inputProps={{
                style: {
                  textAlign: 'right',
                  color: colors.dropboxBlue,
                  fontWeight: 'bold',
                  padding: '7px 10px'
                }
              }}
              FormHelperTextProps={{ style: { textAlign: 'right', marginTop: 0 } }}
            />
          </div>
        </Stack>

      </div>
      <Box className={styles.right}>
        <Slider
          disabled={disabled}
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={handleSlideChange}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'grey' }}>
          <Typography
            variant="body2"
          >
            {minLabel}
          </Typography>
          <Typography
            variant="body2"
          >
            {maxLabel}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}


const DollarFormat = React.forwardRef(
  function DollarFormat(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  }
);

const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        valueIsNumericString
      />
    );
  }
);


const PercentageFormat = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        valueIsNumericString
        suffix="%"
      />
    );
  }
);
