import styles from '../../styles/offer.module.scss';
import { Alert, Button, Divider, Stack } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import colorsStyle from '../../colors.module.scss';
import {
  formatFloat,
  formatUSD,
  getCommissionFee,
  getNetFunding,
  getOriginationFee,
  getPayment,
  getRepayment
} from '../../utils';
import { useData } from '../../DataContext';
import { UploadModal } from './upload-modal';


export function Offer({ className, setSubmittedResult, isLoading, setIsLoading, updateOffer}) {

  const { offerCalculatorFactor, offerConstants } = useData();
  const [uploadOpen, setUploadOpen] = useState(false);
  useEffect(() => {

  }, []);

  const handleUploadOpen = () => {
    setUploadOpen(true);
  }

  const handleUploadClose = () => {
    setUploadOpen(false);
  }

  const getPaymentStandalone = () => {
    return getPayment(getRepayment(offerCalculatorFactor.loadAmount, getFactor()), offerCalculatorFactor.term, offerConstants.durationMatrix?.[offerCalculatorFactor.frequency] || 0);
  };

  const getOfferDetailRows = () => {

    return [
      ['Payment Frequency', offerCalculatorFactor.frequency],
      ['Term', `${offerCalculatorFactor.term} Months`],
      ['Commission', [`${offerCalculatorFactor.commission || 0}%`, formatUSD(getCommissionFee(offerCalculatorFactor.loadAmount, offerCalculatorFactor.commission))].join('|')],
      ['Origination Fee', [`${offerCalculatorFactor.origination || 0}%`, formatUSD(getOriginationFee(offerCalculatorFactor.loadAmount, offerCalculatorFactor.origination))].join('|')],
      ['Third-party/Internal Balance', formatUSD(offerCalculatorFactor.thirdPartyInternalBalance)],
      ['Net Funding Amount', formatUSD(getNetFunding(offerCalculatorFactor.loadAmount, offerCalculatorFactor.origination, offerCalculatorFactor.thirdPartyInternalBalance))]
    ];
  };

  const getFactor = (commission=offerCalculatorFactor.commission, month=offerCalculatorFactor.term)=> {
    return (parseFloat(commission) * 0.01 || 0) + (parseFloat(offerCalculatorFactor.buyRateTable.filter(item => item.month == month)[0]?.rate) || 0)
  }


  return (
    <div className={className}>
      <Stack className={styles.offerContainer}
             justifyContent="center"
             alignItems="center"
             spacing={2.5}>
        <div>
          <h3>Offer Summary</h3>
          <Stack spacing={0.5} className={styles.repaymentContainer}>
            <div className={styles.repaymentTitle}>TOTAL REPAYMENT</div>
            <div
              className={styles.repaymentNumber}>{formatUSD(getRepayment(offerCalculatorFactor.loadAmount, getFactor()))}</div>
          </Stack>

          <Stack spacing={1}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <div>Buy Rate</div>
              <div className={styles.value}>{
                `${offerCalculatorFactor.buyRateTable.filter(item => item.month == offerCalculatorFactor.term)[0]?.rate}`
              }</div>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <div>Factor</div>
              <div className={styles.value}>{formatFloat(getFactor())}</div>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <div>Payment</div>
              <div className={styles.value}>{formatUSD(getPaymentStandalone(), 2)}</div>
            </Stack>
          </Stack>
        </div>
        <Divider orientation="horizontal" flexItem sx={{ bgcolor: colorsStyle.coconut }} />
        <Stack spacing={2}>
          <Stack spacing={1.2} className={styles.rowContainer}>
            {getOfferDetailRows().map((item, index) => (
              <Stack key={index} direction={'row'} justifyContent="space-between" alignItems="flex-start"
                     className={styles.rowItem}>
                <div style={{ textAlign: 'left' }}>{item[0]}</div>
                <Stack direction={'row'} justifyContent="space-between" spacing={1}
                       divider={<Divider orientation="vertical" flexItem
                                         sx={{ bgcolor: colorsStyle.coconut }} />}>
                  {
                    item[1].split('|').map((subValue: string, j: number) => (

                      <div key={j} className={styles.value}>{subValue?.trim()}</div>
                    ))
                  }
                </Stack>

              </Stack>
            ))}
          </Stack>


        </Stack>
        <Divider orientation="horizontal" flexItem sx={{ bgcolor: colorsStyle.coconut }} />
        <UploadModal open={uploadOpen} handleClose={handleUploadClose} disabled={offerCalculatorFactor.status === 'accepted'}
                     setIsLoading={setIsLoading}
                     isLoading={isLoading}
                     setSubmittedResult={setSubmittedResult}
                     updateOffer={updateOffer}
        />
        <Button variant="contained" fullWidth={true}
                color={'secondary'} size={'large'}
                sx={{ fontWeight: 'bold' }} onClick={handleUploadOpen}
        >SELECT THIS OFFER</Button>
      </Stack>
    </div>
  );
}

export default Offer;
